import React from "react";
import './dashboard.css';
import Video from "../../assests/video.mp4";
const Dashboard = () => {
    return (

        <video muted autostart loop autoPlay src={Video} type="video/mp4" />


    )
}
export default Dashboard;