import React from "react";
import './footer.css';
import message from '../../assests/message.png';
import linked from '../../assests/linked.png';
import map from '../../assests/map.png';

const Footer = () => {
    return (
        <div class="card footer h-100">
            <div className="card-body">
                <div class="row justify-content-around">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-12 mt-3">
                        <div className="footer-content">
                            <span className="footer-left-title">
                                <img src={message} alt="" width="25" height="20" class="header-logo" />
                                contact@vriodigital.com
                            </span><br />
                            <div class="mt-1">
                                <span className="footer-left-title" onClick={() => {
                                    window.open("https://www.linkedin.com/company/vrio-digital/")
                                }}>
                                    <img src={linked} alt="" width="25" height="20" class="header-logo" />
                                    linkedin.com/vrio-digital
                                </span>
                            </div>
                        </div>
                    </div>


                    <div class="col-3 hide"></div>


                    <div class="col-5 col-lg-5 col-md-6 col-12 mt-3">
                        <span className="footer-title" onClick={() => {
                            window.open("https://maps.app.goo.gl/d2esMF4aWfPFQQ8J6")
                        }}>
                            <img src={map} alt="" width="15" height="20" class="header-logo" />
                            BLOCK-G, GOPALAN GLOBAL AXIS, Rd Number 9, opp. Satya Sai Hospital, KIADB Industrial Area, Whitefield, Bengaluru, Karnataka 560066
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;